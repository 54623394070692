html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  background-color: #3b3b3b;
  background-image: url(img/background.png);
  background-repeat: repeat;
  background-position: top left;
  color: #f4f4f4;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.25em;
}
@media (min-width: 800px) {
  html,
  body {
    font-size: 17px;
  }
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
div,
html,
body {
  margin: 0;
  padding: 0;
}
p {
  margin: 0.5em 0;
}
strong,
em {
  font-weight: 700;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  line-height: 1.2em;
}
h1 {
  font-size: 1.5em;
  margin: 2em 0 0 0;
}
h1:first-of-type {
  margin-top: 0;
}
@media (min-width: 800px) {
  h1 {
    font-size: 2em;
  }
}
h2 {
  font-size: 1.5em;
  margin: 1.3em 0 0.3em;
}
h2.title:first-of-type {
  margin-top: 0;
}
h3,
h4,
h5,
h6 {
  font-size: 1.2em;
  margin: 1.5em 0 0.5em;
}
a {
  font-weight: 700;
}
a,
a:visited,
a:link {
  color: #ff9900;
  text-decoration: none;
}
a:active,
a:hover {
  color: #cc7a00;
  text-decoration: none;
}
a:focus {
  border-radius: 5px;
}
*::selection {
  background-color: rgba(255, 204, 128, 0.5);
}
*::-moz-selection {
  background-color: rgba(255, 204, 128, 0.5);
}
*:focus {
  box-shadow: 0 0 0 2px #ff9900;
  outline: none;
}
.container {
  width: 100%;
  max-width: 424px;
  padding: 0 12px;
  margin: 12px auto;
}
@media (min-width: 800px) {
  .container {
    padding: 0;
    margin: 5vh auto;
  }
}
.cover {
  display: block;
  width: 100%;
  height: auto;
  max-width: 200px;
  max-height: 200px;
  margin: 0 auto 1em auto;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}
.title {
  text-align: center;
}
.intro {
  text-align: center;
  margin: 0.5em 0 1em 0;
}
.links {
  display: flex;
  flex-wrap: wrap;
}
.links .link {
  flex: 1 1 188px;
  margin: 0 6px 12px 6px;
  background-color: #ff9900;
  border-radius: 5px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 12px;
}
@media (min-width: 800px) {
  .links .link {
    padding: 6px 12px;
  }
}
.links .link,
.links .link:visited,
.links .link:active {
  color: #f4f4f4;
}
.links .link:hover {
  color: #f4f4f4;
  background-color: #cc7a00;
}
.links .link.spotify {
  background-color: #1db954;
}
.links .link.spotify:hover {
  background-color: #1aa34a;
}
.links .link.apple-music {
  background-color: #4aa3f9;
}
.links .link.apple-music:hover {
  background-color: #198af7;
}
.links .link.i-tunes {
  background-color: #db48d8;
}
.links .link.i-tunes:hover {
  background-color: #d733d3;
}
.links .link.bandcamp {
  background-color: #629aa9;
}
.links .link.bandcamp:hover {
  background-color: #4c7e8c;
}
.links .link.sound-cloud {
  background-color: #ff8800;
}
.links .link.sound-cloud:hover {
  background-color: #e67a00;
}
.links .link.deezer {
  background-color: #ff0092;
}
.links .link.deezer:hover {
  background-color: #e60083;
}
.links .link.tidal {
  background-color: #555555;
}
.links .link.tidal:hover {
  background-color: #484848;
}
.links .link.napster {
  background-color: #555555;
}
.links .link.napster:hover {
  background-color: #484848;
}
