@container-width: 400px;

.container {
	width: 100%;
	max-width: @container-width + (@padding-normal * 2);
	padding: 0 @padding-normal;
	margin: @padding-normal auto;
	@media (min-width: @breakpoint-desktop) {
		padding: 0;
		margin: 5vh auto;
	}
}

.cover {
	display: block;
	width: 100%;
	height: auto;
	@cover-size: @container-width / 2;
	max-width: @cover-size;
	max-height: @cover-size;
	margin: 0 auto 1em auto;
	border-radius: @border-radius-size;
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}

.title {
	text-align: center;
}

.intro {
	text-align: center;
	margin: 0.5em 0 1em 0;
}

.links {
	display: flex;
	flex-wrap: wrap;
	.link {
		flex: 1 1 (@container-width / 2) - (@padding-normal);
		margin: 0 @padding-small @padding-normal @padding-small;
		background-color: @accent-color;
		border-radius: @border-radius-size;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: @padding-normal;
		@media (min-width: @breakpoint-desktop) {
			padding: @padding-small @padding-normal;
		}
		&,
		&:visited,
		&:active {
			color: @link-color;
		}
		&:hover {
			color: @link-color;
			background-color: @accent-color-dark;
		}
		&.spotify {
			background-color: #1db954;
			&:hover {
				background-color: darken(#1db954, 5%);
			}
		}
		&.apple-music {
			background-color: #4aa3f9;
			&:hover {
				background-color: darken(#4aa3f9, 10%);
			}
		}
		&.i-tunes {
			background-color: #db48d8;
			&:hover {
				background-color: darken(#db48d8, 5%);
			}
		}
		&.bandcamp {
			background-color: #629aa9;
			&:hover {
				background-color: darken(#629aa9, 10%);
			}
		}
		&.sound-cloud {
			background-color: #ff8800;
			&:hover {
				background-color: darken(#ff8800, 5%);
			}
		}
		&.deezer {
			background-color: #ff0092;
			&:hover {
				background-color: darken(#ff0092, 5%);
			}
		}
		&.tidal {
			background-color: #555555;
			&:hover {
				background-color: darken(#555555, 5%);
			}
		}
		&.napster {
			background-color: #555555;
			&:hover {
				background-color: darken(#555555, 5%);
			}
		}
	}
}
