html,
body {
	font-family: @font-family-body;
	background-color: @site-background-color;
	background-image: url(./img/background.png);
	background-repeat: repeat;
	background-position: top left;
	color: @text-color;
	font-size: @font-size-mobile;
	font-weight: 400;
	line-height: 1.25em;

	@media (min-width: @breakpoint-desktop) {
		font-size: @font-size-regular;
	}
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

div,
html,
body {
	margin: 0;
	padding: 0;
}

p {
	margin: 0.5em 0;
}

strong,
em {
	font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: @font-family-body;
	font-weight: 400;
	line-height: 1.2em;
}

h1 {
	font-size: 1.5em;
	margin: 2em 0 0 0;
	&:first-of-type {
		margin-top: 0;
	}
	@media (min-width: @breakpoint-desktop) {
		font-size: 2em;
	}
}

h2 {
	font-size: 1.5em;
	margin: 1.3em 0 0.3em;
	&.title:first-of-type {
		margin-top: 0;
	}
}

h3,
h4,
h5,
h6 {
	font-size: 1.2em;
	margin: 1.5em 0 0.5em;
}

a {
	font-weight: 700;
	&,
	&:visited,
	&:link {
		color: @accent-color;
		text-decoration: none;
	}
	&:active,
	&:hover {
		color: @accent-color-dark;
		text-decoration: none;
	}
	&:focus {
		border-radius: @border-radius-size;
	}
}

*::selection {
	background-color: fade(@accent-color-light, 50%);
}
*::-moz-selection {
	background-color: fade(@accent-color-light, 50%);
}

*:focus {
	box-shadow: 0 0 0 2px @accent-color;
	outline: none;
}
